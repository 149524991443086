import { Button, Checkbox, Form, Input } from 'antd';

export function StockControlForm({
	isShowOptions,
}: { isShowOptions?: boolean }) {
	const f = Form.useFormInstance();
	const showCols = Form.useWatch('showCols', f);

	return (
		<div className="gap-2  flex flex-row flex-wrap ">
			<Form.Item label="specifications" name="specifications">
				<Input size="small" />
			</Form.Item>
			<Form.Item label="brand" name={'brand'}>
				<Input size="small" />
			</Form.Item>
			<Form.Item label="ack" name={'ack'}>
				<Input size="small" />
			</Form.Item>
			<Form.Item label="br#" name={'br#'}>
				<Input size="small" />
			</Form.Item>
			<Form.Item label="remark" name={'remark'}>
				<Input size="small" />
			</Form.Item>
			<Form.Item
				label="uuid"
				name={'uuid'}
				hidden={!showCols?.includes('uuid')}
			>
				<Input size="small" />
			</Form.Item>
			<Form.Item>
				<Button
					size="small"
					onClick={() => {
						// biome-ignore lint/complexity/noForEach: <explanation>
						['specifications', 'brand', 'ack', 'br#', 'remark', 'uuid'].forEach(
							(i) => f.setFieldValue(i, undefined),
						);
					}}
				>
					重置
				</Button>
			</Form.Item>
			<Form.Item
				label="显示"
				name={'showCols'}
				hidden={!isShowOptions}
				className="justify-end"
			>
				<Checkbox.Group
					options={[
						{ label: '显示 uuid', value: 'uuid' },
						{ label: '显示 source', value: 'source' },
					]}
				/>
			</Form.Item>
		</div>
	);
}
